import { Signer } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import mqtt from 'mqtt';

import config from './config.json';

const IoT = {
    connected: false,

    connection: null,

    connect: async function() {
        if (this.connected)
            return this.connection

        const credentials = await Auth.currentCredentials();

        return new Promise((resolve, reject) => {
            const signedUrl = Signer.signUrl(`wss://${config.IoT.endpoint}/mqtt`, {
                access_key: credentials.accessKeyId,
                secret_key: credentials.secretAccessKey,
                session_token: credentials.sessionToken
            }, {
                service: 'iotdevicegateway',
                region: config.IoT.region,
            })
            this.connection = mqtt.connect(signedUrl, {
                clientId: `AssociateUI_${credentials.accessKeyId}_${Date.now().toString}`
            })
            .on('connect', () => {
                this.connected = true;
                resolve(this.connection);
            });
        });
    },

    disconnect: function() {
        if (this.connected) {
            this.connection.end();
    
            this.connected = false;
        }
    }
};

export default IoT;
