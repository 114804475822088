import React, {useState, useEffect} from 'react';

import IoT from './IoT.js'


var completeScan = null;

function ScannerControl(props) {
  const displayName = props.scanner.name;
  const scannerName = props.scanner.scannerName;
  const headThingName = props.scanner.headThingName;

  completeScan = props.completeScan;

  const [scannerStatus, setScannerStatus] = useState("Disconnected");
  const [headStatus, setHeadStatus] = useState("Disconnected");

  // Subscribe to topics when thing choices change
  useEffect(() => {
    if (!scannerName || !headThingName) return;

    // Scanner connection
    const scannerTopic = `SS20/${scannerName}`;

    // Head UI connection
    const headTopic = `$aws/things/${headThingName}/shadow`;

    const topics = [
      scannerTopic + '/state',
      headTopic + '/get/accepted',
      headTopic + '/update/accepted'
    ];

    (async () => {

      const iotConnection = await IoT.connect();

      console.log("Connecting to scanner: " + scannerName);

      iotConnection.on('message', (topic, payload) => {
        const report = JSON.parse(payload);

        switch (topic) {
          case scannerTopic + '/state':
            try {
              setScannerStatus(report.status);

              if (report.status === 'MultiscanMeasure Completed') {
                setTimeout(completeScan, 10000);
              }
            } catch (ex) {
              console.log("Received invalid scanner IoT payload");
            }
            break;

          case headTopic + '/get/accepted':
          case headTopic + '/update/accepted':
            try {
              setHeadStatus(report.state.reported.page);
            } catch (ex) {
              console.log("Received invalid head IoT payload");
            }
            break;
          default:
            break;
        }
      });
      
      iotConnection.subscribe(topics, {}, () => {
        iotConnection.publish(scannerTopic + '/get', "{}");
        iotConnection.publish(headTopic + '/get', "{}");
      });
    })();

    return () => {
      console.log("Disconnecting from: " + scannerName);
      IoT.connection.unsubscribe(topics)
      setScannerStatus("Disconnected");
      setHeadStatus("Disconnected");
    };
  }, [scannerName, headThingName]);

  return (
    <section className="ScannerControl">
      <h2>Scanning</h2>
      <div>Scanner: {displayName}</div>
      <div>Scanner Status: {scannerStatus}</div>
      <div>Head Status: {headStatus}</div>
      <div className="actions">
        <button onClick={async () => {
          if (!IoT.connected) return;

          const customer = await props.provisionCustomer(props.customer);

          console.log("Priming scanner for user: " + customer.userId)
          IoT.connection.publish(`$aws/things/${headThingName}/shadow/update`, JSON.stringify({
            state: {
              desired: {
                page: "Start",
                userName: customer.name,
                userId: customer.userId
              }
            }
          }));
        }}>Start</button>
      </div>
    </section>
  )
}

export default ScannerControl;
