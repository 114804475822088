import _ from 'lodash';

import sample from './sample.json';


async function GetLocationChoices() {
    const LocationSelections = {
        locations: sample.locations,
        fitPrefStations: sample.fitPrefStations,
        scanners: sample.scanners
    }

    return LocationSelections;
}

function PickDefaultLocation(locations, providedSelections) {
    if (providedSelections.location)
        return providedSelections.location
    else if (providedSelections.scanner)
        return _.find(locations, location =>
        location.id === providedSelections.scanner.location
        )
    else if (providedSelections.fitPrefStation)
        return _.find(locations, location =>
        location.id === providedSelections.fitPrefStation.location
        )
    else
        return locations[0];
}

function DefaultLocationSelections(locations, fitPrefStations, scanners, providedSelections) {
    // Pick the default location by any provided scanner or fit preference station
    const defaultLocation = PickDefaultLocation(locations, providedSelections);

    // Pick the default scanner and fit preference station by the location
    const defaultSelections = {
    location: defaultLocation,

    fitPrefStation: _.find(fitPrefStations, fitPrefStation =>
        fitPrefStation.location === defaultLocation.id
    ),

    scanner: _.find(scanners, scanner =>
        scanner.location === defaultLocation.id
    ),
    
    ...providedSelections
    }

    return defaultSelections
}

async function LocationSelections(params) {
    const providedSelectionIds = _.pick(params, [
        "location",
        "fitPrefStation",
        "scanner"
    ]);

    const {locations, fitPrefStations, scanners} = await GetLocationChoices();

    // Find provided options by IDs
    const providedSelections = _.pickBy({
        location: _.find(locations, location =>
            location.id === providedSelectionIds.location
        ),

        fitPrefStation: _.find(fitPrefStations, fitPrefStation =>
            fitPrefStation.id === providedSelectionIds.fitPrefStation
        ),

        scanner: _.find(scanners, scanner =>
            scanner.id === providedSelectionIds.scanner
        ),
    }, _.identity);

    return {
        defaultSelections: DefaultLocationSelections(locations, fitPrefStations, scanners, providedSelections),

        // Choose active scanner and fit preference station by location
        locationSelectionChangeHandler: (state, setter) => {
            return function(e) {
                const newState = {...state, ...e};
                const locationfitPrefStations = _.filter(fitPrefStations, s =>
                    s.location === newState.location.id
                )
                const locationScanners = _.filter(scanners, s =>
                    s.location === newState.location.id
                )

                if (newState.fitPrefStation.location !== newState.location.id)
                    newState.fitPrefStation = (providedSelections.scanfitPrefStation &&
                                        locationfitPrefStations.indexOf(providedSelections.fitPrefStation) >= 0)
                                        ? providedSelections.fitPrefStation
                                        : locationfitPrefStations[0]

                if (newState.scanner.location !== newState.location.id)
                    newState.scanner = (providedSelections.scanner &&
                                        locationScanners.indexOf(providedSelections.scanner) >= 0)
                                        ? providedSelections.scanner
                                        : locationScanners[0]

                setter(newState);
            }
        },

        locationSelectionChoices: (currentSelections) => {
            // Location selection(s)
            const locationChoices = (providedSelections.location)
                                    ? [providedSelections.location]
                                    : locations;
            
            // Fit preference station selection(s)
            const locationFitPrefStations = _.filter(fitPrefStations, s =>
                s.location === currentSelections.location.id
            )
            const fitPrefStationChoices = (providedSelections.fitPrefStation && 
                                        locationFitPrefStations.indexOf(providedSelections.fitPrefStation) >= 0)
                                            ? [providedSelections.fitPrefStation]
                                            : locationFitPrefStations;
            
            // Scanner selection(s)
            const locationScanners = _.filter(scanners, s =>
                s.location === currentSelections.location.id
            )
            const scannerChoices = (providedSelections.scanner &&
                                    locationScanners.indexOf(providedSelections.scanner) >= 0)
                                    ? [providedSelections.scanner]
                                    : locationScanners;

            return {
                locationChoices: locationChoices,
                fitPrefStationChoices: fitPrefStationChoices,
                scannerChoices: scannerChoices
            }
        }
    }
}

export const InitialLocSels = {
    locSels: {
    "location": [
        {
            "id": "1",
            "name": "Unknown",
            "products": [
            ]
        }
    ],
    "fitPrefStation": [
        {
            "id": "1",
            "name": "Unknown",
            "location": "1"
        }
    ],
    "scanner": [
        {
            "id": "1",
            "name": "Unknown",
            "location": "1",
            "scannerName": "Unknown",
            "headThingName": "Head_Unknown"
        }
    ]
  },
  locSelChoices: {
    "locationChoices": [
        {
            "id": "1",
            "name": "Unknown",
            "products": [
            ]
        }
    ],
    "fitPrefStationChoices": [
        {
            "id": "1",
            "name": "1",
            "location": "1"
        }
    ],
    "scannerChoices": [
        {
            "id": "1",
            "name": "Unknown",
            "location": "1",
            "scannerName": "Unknown",
            "headThingName": "Head_Unknown"
        }
    ]
  },
  handleLocSelChange: null
};

export default LocationSelections;
