import React from 'react';

function CustomerInfo(props) {
  const customer = props.customer;

  return (
    <section className="CustomerInfo">
      <h2>Customer</h2>
      <table>
        <tbody>
          <tr>
            <td>Customer ID</td>
            <td>{customer.customerId}</td>
          </tr>

          <tr>
            <td>Name</td>
            <td>{customer.name}</td>
          </tr>

          <tr>
            <td>Gender</td> 
            <td>{customer.gender}</td>
          </tr>
        </tbody>
      </table>
    </section>
  )
}

function OptionalSelect(props) {
  var choices = props.choices;
  if (choices.length > 1) {
    choices = <select onChange={props.onChange} value={props.choice}>
                {choices.map(option => <option key={option}>{option}</option>)}
              </select>;
  }
  return choices;
}

function LocationInfo(props) {
  const locations = props.locations;
  // const fitPrefStations = props.fitPrefStations;
  const scanners = props.scanners;

  // Names
  const locationNames = props.locations.map(location => location.name);
  // const fitPrefStationNames = fitPrefStations.map(fitPrefStation => fitPrefStation.name);
  const scannerNames = scanners.map(scanner => scanner.name);

  function onOptionChange(name, list) {
    return (e) => {
      const selection = {
        [name]: list[e.target.selectedIndex]
      };

      props.onChange(selection);
    }
  }

  return (
    <section className="LocationInfo">
      <h2>Location</h2>

      <table>
        <tbody>
          <tr>
            <td>Location</td>
            <td>
              <OptionalSelect choices={locationNames}
                              choice={props.state.location.name}
                              onChange={onOptionChange("location", locations)} />
            </td>
          </tr>

          {/* <tr>
            <td>Fit Preference Station</td>
            <td>
              <OptionalSelect choices={fitPrefStationNames}
                              choice={props.state.fitPrefStation.name}
                              onChange={onOptionChange("fitPrefStation", fitPrefStations)} />
            </td>
          </tr> */}

          <tr>
            <td>Scanner</td>
            <td>
              <OptionalSelect choices={scannerNames}
                              choice={props.state.scanner.name}
                              onChange={onOptionChange("scanner", scanners)} />
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  )
}

function Info(props) {
  return (
    <div className="Info">
      <CustomerInfo customer={props.customer}/>
      <LocationInfo locations={props.locations}
                    fitPrefStations={props.fitPrefStations}
                    scanners={props.scanners}
                    state={props.state}
                    onChange={props.onChange}/>
    </div>
  )
}

export default Info;
